import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import {
  BodyHeader,
  BodyActions,
  ContractHeaderLabel,
  ContractHeaderSubLabel,
} from 'Components/shared/body'
import GoBack from 'Assets/images/go_back.svg'
import {
  selectContractState,
  setContractState,
} from 'Slices/contracts/ContractStateSlice'
import { ContractFormProps } from './types'
import { Permissions } from 'Configs/userPermissions'
import {
  selectContractConfiguration,
  resetContractStates,
} from 'Slices/contracts'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'Utils/store'
import { Loading } from 'Utils/loading'

const Container = styled('div')(() => ({
  position: 'relative',
  marginBottom: '20px',
}))

const PreviousPage = styled(GoBack)(() => ({
  position: 'absolute',
  left: '-40px',
  top: '8px',
  cursor: 'pointer',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const UpdateButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

export const ContractDetails = memo(({ submitForm }: ContractFormProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    contractSource,
    contractName,
    createdAt: contractCreatedAt,
    updatedAt: contractUpdatedAt,
    isDisabledContract,
    contractId,
    contractInstitutionId,
  } = useSelector(selectContractConfiguration)

  const { disableUpdate, isDuplicating, canDuplicateContract } =
    useSelector(selectContractState)
  const isNewContract = !contractId

  const requiredPermission =
    isNewContract || isDuplicating
      ? Permissions.CreateContract
      : Permissions.UpdateContract
  const { hasPermission: hasCUPermission, isFetching: isFetchingPermission } =
    useHasPermission(requiredPermission)

  const disabledContract = isDisabledContract || !hasCUPermission

  const handleOnBackArrowClick = () => {
    dispatch(resetContractStates())
    navigate(`/institution/${contractInstitutionId}/${contractSource}`)
  }

  useEffect(() => {
    if ((isNewContract || isDuplicating) && !hasCUPermission) {
      handleOnBackArrowClick()
    }
  }, [isNewContract, isDuplicating, hasCUPermission])

  if (isFetchingPermission) return <Loading />
  
  return (
    <BodyHeader>
      <Container>
        <PreviousPage onClick={handleOnBackArrowClick} />
        <ContractHeaderLabel
          label={`${
            isDuplicating ? 'New Contract based on ' : ''
          }${contractName}`}
        >
          <ContractHeaderSubLabel
            label={'DATE CREATED'}
            value={contractCreatedAt}
          />
          <ContractHeaderSubLabel label={'UPDATED'} value={contractUpdatedAt} />
        </ContractHeaderLabel>
      </Container>
      <BodyActions>
        {canDuplicateContract && (
          <UpdateButton
            onClick={() => {
              dispatch(setContractState({ disableUpdate: true }))
              dispatch(setContractState({ isDuplicating: !isDuplicating }))
            }}
          >
            {isDuplicating ? 'Cancel' : 'Duplicate'}
          </UpdateButton>
        )}
        {(!disabledContract || isDuplicating) && (
          <UpdateButton onClick={submitForm} disabled={disableUpdate}>
            {isDuplicating ? 'Add' : 'Update'}
          </UpdateButton>
        )}
      </BodyActions>
    </BodyHeader>
  )
})
