import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SegmentInput } from 'Components/contracts/segment'
import {
  setContract,
  setNoOfLeads,
  setSegmentName,
  setPricePerLead,
  setPricePerLeadString,
  selectNewOnDemandSegment,
} from './NewOnDemandSegmentSlice'
import { ContractSource } from 'Types/index'

interface EditDuplicateOnDemandProps {
  control: any
  setFormError: any
  clearFormErrors: any
  showSidebar: boolean
  candidateContract?: any
  segmentData?: any
}

export const EditDuplicateOnDemand = ({
  control,
  candidateContract,
  segmentData,
}: EditDuplicateOnDemandProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const {
    contract,
    segmentsLeadsTotal,
    noOfLeads,
    segmentName,
    pricePerLead,
    pricePerLeadString,
  } = useSelector(selectNewOnDemandSegment)

  const remainingLeads = contract?.lead_cap - segmentsLeadsTotal

  const setSelectedContract = (contract: any) => {
    dispatch(setContract(contract))
  }

  const handleNoOfLeadsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setNoOfLeads(String(e.target.value)))
  }

  const handlePricePerLead = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPricePerLeadString(e.target.value))
    dispatch(setPricePerLead(parseFloat(e.target.value)))
  }

  const handleSegmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSegmentName(e.target.value))
  }

  useEffect(() => {
    if (candidateContract && segmentData) {
      setSelectedContract(candidateContract)
      dispatch(setSegmentName(segmentData['name']))
      dispatch(setPricePerLeadString(segmentData['price_per_lead'].toString()))
      dispatch(setPricePerLead(segmentData['price_per_lead'].toString()))
      dispatch(setNoOfLeads(segmentData['lead_total'].toString()))
    }
  }, [candidateContract, segmentData])

  return (
    <SegmentInput
      setSelectedContract={setSelectedContract}
      handleNoOfLeadsChange={handleNoOfLeadsChange}
      handlePricePerLeadChange={handlePricePerLead}
      handleSegmentNameChange={handleSegmentNameChange}
      chosenContract={contract}
      segmentName={segmentName}
      noOfLeads={noOfLeads}
      pricePerLead={pricePerLead}
      pricePerLeadString={pricePerLeadString}
      remainingLeads={remainingLeads}
      control={control}
      source={ContractSource.candidates}
    />
  )
}
