import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import {
  selectContractConfiguration,
  setContractConfiguration,
} from 'Slices/contracts/ContractConfigurationSlice'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { DialogField } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { DatePickerField } from 'Components/shared/datepicker'
import { DatePickerContainer as DatePicker } from 'Containers/shared/datepicker/DatePicker'
import { formatDate } from 'Utils/dateUtils'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import {
  AudienceTypeHelper,
  ContractSource,
  ContractSourceReadable,
  ContractType,
  PurchaseType,
} from 'Types/index'
import { ContractFormProps } from './types'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { selectContractState } from 'Slices/contracts/ContractStateSlice'
import { Loading } from 'Utils/loading'
import { current } from '@reduxjs/toolkit'

const ContractValue = styled('div')(({ theme, disabledContract }) => ({
  color: disabledContract
    ? theme.palette.primary.dark
    : theme.palette.secondary['100'],
  fontSize: '0.875rem',
  lineHeight: '21px',
}))

export const ContractConfiguration = ({ control }: ContractFormProps) => {
  const theme = useTheme()
  const hasMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()

  const {
    contractId,
    contractName,
    contractType,
    contractSource,
    isDisabledContract,
    audienceType: contractAudienceType,
    purchaseType: contractPurchaseType,
    leadCap: contractLeadCap,
    contractInstitutionId,
    purchaseType,
    salesforceID,
    startDate,
    endDate,
    notes,
  } = useSelector(selectContractConfiguration)
  const { fieldsAvailableForContract, isDuplicating } =
    useSelector(selectContractState)

  const isExistingContract = !!contractId && !!contractInstitutionId
  const isNewContract = !isExistingContract

  if (!isExistingContract && !isNewContract) {
    throw new Error(
      'Missing contract details required for new and existing contract.'
    )
  }

  const { hasPermission: canUpdateOrCreateContractConfig } = useHasPermission(
    Permissions.UpdateContractConfiguration
  )
  const disabledEditing =
    isDisabledContract ||
    !canUpdateOrCreateContractConfig ||
    !contractSource ||
    !contractType

  const { hasPermission: canViewNotes } = useHasPermission(
    Permissions.ViewContractNotes
  )
  //TODO: backend needs to add edit permission
  // const { hasPermission: canEditNotes } = useHasPermission(
  //   Permissions.EditContractNotes
  // )
  const canEditNotes = canViewNotes && !disabledEditing

  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(contractSource, { skip: !contractSource })

  if (isFetchingAudienceTypes) return <Loading />

  const minContractDate = new Date(startDate)
  const currentDate = new Date()
  const maxContractDate = new Date(endDate)

  return (
    <ContractSection>
      <ContractSectionHeader label={'Contract Configuration'} />
      <ContractConfigurationFieldContainer>
        {(isDuplicating || isNewContract) && (
          <DialogField id='add-contract-name' label='*Contract Name'>
            <Input
              id='add-contract-name'
              name='contractName'
              placeholder='Add Contract Name'
              control={control}
              rules={{
                required: 'Contract Name is a required field',
              }}
              style={{ width: '401px' }}
              value={contractName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  setContractConfiguration({ contractName: e.target.value })
                )
              }}
            />
          </DialogField>
        )}

        {fieldsAvailableForContract.includes('contract_source') && (
          <DialogField
            id='contract-source'
            label={isNewContract ? '*Contract Source' : 'Contract Source'}
          >
            {isNewContract ? (
              <SingleSelectDropdown
                id='add-contract-source'
                style={hasMdBreakpoint ? { width: '229px' } : {}}
                value={ContractSource.toHumanName(contractSource)}
                name='ContractSource'
                control={control}
                rules={{
                  required: 'Contract Source is a required field',
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setContractConfiguration({
                      contractSource: ContractSource.toHumanName(
                        e.target.value as ContractSourceReadable
                      ),
                    })
                  )
                }
                values={ContractSource.getHumanList()}
              />
            ) : (
              <ContractValue disabledContract={disabledEditing}>
                {ContractSource.toHumanName(contractSource)}
              </ContractValue>
            )}
          </DialogField>
        )}
        {fieldsAvailableForContract.includes('contract_type') && (
          <DialogField
            id='contract-type'
            label={isNewContract ? '*Contract Type' : 'Contract Type'}
          >
            {isNewContract ? (
              <SingleSelectDropdown
                id='add-contract-type'
                style={hasMdBreakpoint ? { width: '229px' } : {}}
                value={ContractType.toHumanName(contractType)}
                name='contractType'
                control={control}
                rules={{
                  required: 'Contract Type is a required field',
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setContractConfiguration({
                      contractType: ContractType.toHumanName(e.target.value),
                    })
                  )
                }
                values={ContractType.getHumanList()}
              />
            ) : (
              <ContractValue disabledContract={disabledEditing}>
                {ContractType.toHumanName(contractType)}
              </ContractValue>
            )}
          </DialogField>
        )}

        {fieldsAvailableForContract.includes('salesforce_id') && (
          <DialogField id='salesforceID' label='*Salesforce ID'>
            {disabledEditing ? (
              <ContractValue>{salesforceID}</ContractValue>
            ) : (
              <Input
                id='add-salesforce-id'
                placeholder='Add Salesforce ID'
                name='salesforceID'
                value={salesforceID}
                style={hasMdBreakpoint ? { width: '401px' } : {}}
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message:
                      'Salesforce ID should contain only letters and numbers',
                  },
                  minLength: {
                    value: 11,
                    message:
                      'Salesforce ID should have a minimum length of 11 characters',
                  },
                  maxLength: {
                    value: 18,
                    message: 'Salesforce ID should have a maximum length of 18',
                  },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    setContractConfiguration({ salesforceID: e.target.value })
                  )
                }}
                disabled={disabledEditing}
              />
            )}
          </DialogField>
        )}

        <DialogField
          id='audience-type'
          label={isNewContract ? '*Audience Type' : 'Audience Type'}
        >
          {isNewContract ? (
            <SingleSelectDropdown
              id='add-audience-type'
              style={hasMdBreakpoint ? { width: '229px' } : {}}
              value={
                contractAudienceType
                  ? AudienceTypeHelper.getReadableString(
                      audienceTypes,
                      contractAudienceType
                    )
                  : ''
              }
              name='audienceType'
              rules={{
                required: 'Audience Type is a required field',
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const key = AudienceTypeHelper.getAudienceType(
                  audienceTypes,
                  e.target.value
                )
                dispatch(setContractConfiguration({ audienceType: key }))
              }}
              values={audienceTypes?.map((type) => type.name).sort()}
            />
          ) : (
            <ContractValue disabledContract={disabledEditing}>
              {AudienceTypeHelper.getReadableString(
                audienceTypes,
                contractAudienceType
              )}
            </ContractValue>
          )}
        </DialogField>

        {fieldsAvailableForContract.includes('purchase_type') && (
          <DialogField
            id='purchase-type'
            label={isNewContract ? '*Purchase Type' : 'Purchase Type'}
          >
            {isNewContract ? (
              <SingleSelectDropdown
                id='add-purchase-type'
                style={hasMdBreakpoint ? { width: '229px' } : {}}
                value={purchaseType}
                name='purchaseType'
                rules={{
                  required: 'Purchase Type is a required field',
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setContractConfiguration({
                      purchaseType: e.target.value as PurchaseType,
                    })
                  )
                }
                values={PurchaseType.toList()}
              />
            ) : (
              <ContractValue disabledContract={disabledEditing}>
                {contractPurchaseType}
              </ContractValue>
            )}
          </DialogField>
        )}

        {fieldsAvailableForContract.includes('lead_cap') && (
          <DialogField id='contract-lead-cap' label='*Contract Lead Cap'>
            {disabledEditing ? (
              <ContractValue disabledContract={disabledEditing}>
                {contractLeadCap ? String(contractLeadCap) : ''}
              </ContractValue>
            ) : (
              <Input
                id='update-contract-lead-cap'
                value={contractLeadCap ? String(contractLeadCap) : ''}
                name='leadCap'
                control={control}
                rules={{
                  required: 'Contract Lead Cap is a required field',
                }}
                placeholder='0'
                style={hasMdBreakpoint ? { width: '229px' } : {}}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setContractConfiguration({
                      leadCap: Number(e.target.value),
                    })
                  )
                }
              />
            )}
          </DialogField>
        )}

        <DatePickerField id='add-delivery-period' label='*Delivery Period'>
          {disabledEditing ? (
            <ContractValue disabledContract={disabledEditing}>
              {startDate} to {endDate}
            </ContractValue>
          ) : (
            <>
              <DatePicker
                label={'Starting Date'}
                name='startDate'
                value={startDate}
                onChange={(newValue) => {
                  dispatch(
                    setContractConfiguration({
                      startDate: formatDate(newValue),
                    })
                  )
                }}
                disablePast={false}
                disabled={disabledEditing}
                control={control}
                rules={{
                  required: 'Start Date is a required field',
                  validate: (value) => {
                    if (!value || isNaN(new Date(value).getTime())) {
                      return 'Invalid date'
                    }
                    if (value > maxContractDate) {
                      return 'Start date must be after End date'
                    }
                    return true
                  },
                }}
              />
              <DatePicker
                label={'End Date'}
                name='endDate'
                value={endDate}
                onChange={(newValue) => {
                  dispatch(
                    setContractConfiguration({ endDate: formatDate(newValue) })
                  )
                }}
                minDate={minContractDate}
                disablePast={true}
                disabled={disabledEditing}
                control={control}
                rules={{
                  required: 'End Date is a required field',
                  validate: (value) => {
                    if (!value || isNaN(new Date(value).getTime())) {
                      return 'Invalid date'
                    }
                    if (value < minContractDate) {
                      return 'End date must be after Start date'
                    }
                    if (value < currentDate) {
                      return 'End date cannot be in the past'
                    }
                    return true
                  },
                }}
              />
            </>
          )}
        </DatePickerField>
        {canViewNotes && (
          <DialogField id='notes-field' label='Notes' alignSelf='flex-start'>
            {canEditNotes ? (
              <Input
                id='add-contract-notes'
                placeholder='Add Notes'
                value={notes}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(setContractConfiguration({ notes: e.target.value }))
                }}
                multiline
                rows={5}
                style={hasMdBreakpoint ? { width: '504px' } : {}}
                disabled={disabledEditing}
              />
            ) : (
              <Input
                id='view-contract-notes'
                placeholder=''
                value={notes}
                multiline
                rows={5}
                style={hasMdBreakpoint ? { width: '504px' } : {}}
                disabled={disabledEditing}
              />
            )}
          </DialogField>
        )}
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}
