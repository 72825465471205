import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  useGetSegmentsForContractQuery,
  useDeactivateSegmentMutation,
} from 'Services/segments/segments'
import { SegmentsListSection } from 'Components/contracts/segment'
import { SegmentsDetails } from 'Containers/shared/contracts/segments'
import { Loading } from 'Utils/loading'
import { useToggle } from 'Hooks/useToggle'
import { ContractSource, SegmentAction } from 'Types/index'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Permissions } from 'Configs/userPermissions'
import {
  selectContractConfiguration,
  setContractConfiguration,
} from 'Slices/contracts'
import { useDispatch, useSelector } from 'react-redux'

const KEY_LEAD_TOTAL = 'lead_total'
const KEY_LEADS_DELIVERED = 'leads_delivered'
const KEY_LEAD_CAP = 'lead_cap'

const ColumnDataConfiguration = {
  [ContractSource.inquiries]: [
    {
      columnName: 'Segment Name',
      columnKey: 'name',
      columnWidth: 250,
      sortOrder: 'ASC',
    },
    {
      columnName: 'Segment Lead Cap',
      columnKey: KEY_LEAD_TOTAL,
      columnWidth: 75,
      sortOrder: 'ASC',
    },
    {
      columnName: '% of Lead Cap Requested',
      columnKey: 'cap_requested',
      columnWidth: 75,
      sortOrder: 'ASC',
    },
    {
      columnName: 'Leads Delivered',
      columnKey: KEY_LEADS_DELIVERED,
      columnWidth: 75,
      sortOrder: 'ASC',
    },
    {
      columnName: '% of Lead Delivered',
      columnKey: 'cap_delievered',
      columnWidth: 75,
      sortOrder: 'ASC',
    },
    {
      columnName: 'Status',
      columnKey: 'active_status',
      columnWidth: 100,
      sortOrder: 'ASC',
    },
  ],
  [ContractSource.candidates]: [
    {
      columnName: 'Segment Name',
      columnKey: 'name',
      columnWidth: 250,
      sortOrder: 'ASC',
    },
    {
      columnName: 'Leads Delivered',
      columnKey: KEY_LEADS_DELIVERED,
      columnWidth: 100,
      sortOrder: 'ASC',
    },
    {
      columnName: 'Price per Lead',
      columnKey: 'price_per_lead',
      columnWidth: 100,
      sortOrder: 'ASC',
    },
    {
      columnName: 'Status',
      columnKey: 'active_status',
      columnWidth: 100,
      sortOrder: 'ASC',
    },
  ],
}
const SourceStatusMap = {
  [ContractSource.inquiries]: {
    false: 'Deactivated',
    true: 'Active',
  },
  [ContractSource.candidates]: {
    false: 'In Queue',
    true: 'Complete',
  },
}

export const SegmentsList = () => {
  const navigate = useNavigate()
  const [segmentDetails, setSegmentDetails] = useToggle(false)
  const [rowIdx, setRowIdx] = useState(-1)
  const dispatch = useDispatch()
  const { ipedId } = useParams()

  const {
    contractId,
    contractSource,
    isDisabledContract,
    leadCap: contractLeadCap,
  } = useSelector(selectContractConfiguration)

  const { data: segments, isLoading: isLoadingSegments } =
    useGetSegmentsForContractQuery(
      {
        ipedId,
        contractId: contractId,
      },
      { skip: !ipedId || !contractId }
    )
  const columnData = ColumnDataConfiguration[contractSource]

  const [deactivateSegment, { isLoading: isDeactivatingSegment }] =
    useDeactivateSegmentMutation()

  const { segmentRows: flattenSegments, totals: segmentTotals } =
    useMemo(() => {
      let segmentRows = []
      let totals = {}
      if (segments && contractId) {
        segments?.forEach((segment: any) => {
          let row = [segment.id]
          columnData.forEach((column) => {
            if (column.columnKey == 'cap_requested')
              row.push(
                `${((segment[KEY_LEAD_TOTAL] / contractLeadCap) * 100).toFixed(
                  2
                )}%`
              )
            else if (column.columnKey == 'cap_delievered')
              row.push(
                `${(
                  (segment[KEY_LEADS_DELIVERED] / segment[KEY_LEAD_TOTAL]) *
                  100
                ).toFixed(2)}%`
              )
            else if (column.columnKey == 'active_status')
              row.push(SourceStatusMap[contractSource][segment.is_active])
            else if (
              column.columnKey in segment &&
              segment[column.columnKey] !== null
            )
              row.push(segment[column.columnKey].toLocaleString())
            else if (
              column.columnKey in segment &&
              segment[column.columnKey] === null
            )
              row.push('0')
            else row.push('')
            // store numbers totals
            if (typeof segment[column.columnKey] === 'number')
              totals[column.columnKey] =
                (totals[column.columnKey] || 0) +
                (segment.is_active ? segment[column.columnKey] : 0)
          })
          segmentRows.push(row)
        })
        let totalRow = ['99-total']
        columnData.forEach((column) => {
          if ('name' == column.columnKey) totalRow.push('Total')
          else if (column.columnKey in totals)
            totalRow.push(totals[column.columnKey].toLocaleString())
          else if (
            'cap_requested' == column.columnKey &&
            KEY_LEADS_DELIVERED in totals
          )
            totalRow.push(
              `${(
                (Number(totals[KEY_LEADS_DELIVERED]) / contractLeadCap) *
                100
              ).toFixed(2)}%`
            )
          else if (
            'cap_delievered' == column.columnKey &&
            KEY_LEADS_DELIVERED in totals
          )
            totalRow.push(
              `${(
                (Number(totals[KEY_LEADS_DELIVERED]) / contractLeadCap) *
                100
              ).toFixed(2)}%`
            )
          else totalRow.push('')
        })
        segmentRows.push(totalRow)
      }
      return { segmentRows, totals }
    }, [segments, contractId])

  useEffect(() => {
    if (KEY_LEAD_TOTAL in segmentTotals) {
      dispatch(
        setContractConfiguration({
          segmentLeadCapTotal: Number(segmentTotals[KEY_LEAD_TOTAL]),
        })
      )
    }
  }, [segmentTotals, KEY_LEAD_TOTAL, dispatch])

  const handleRowClick = (rowIdx: number) => {
    setSegmentDetails()
    setRowIdx(rowIdx)
  }

  const closeSegmentDetailsDialog = () => {
    setSegmentDetails()
  }

  const handleDeactivateSegment = () => {
    closeSegmentDetailsDialog()
    deactivateSegment({
      ipedId,
      segmentId: segments[rowIdx].id,
      isActive: false,
    })
  }

  const handleDuplicateSegment = () => {
    closeSegmentDetailsDialog()
    navigate(
      `/institution/${ipedId}/${contractSource}/contracts/segment/${segments[rowIdx].id}` +
        `?action=${SegmentAction.duplicate}`
    )
  }

  const { hasPermission: canUpdateOrCreateContractConfig } = useHasPermission(
    Permissions.UpdateContractConfiguration
  )
  const disabledContract =
    !canUpdateOrCreateContractConfig || isDisabledContract

  if (isLoadingSegments || isDeactivatingSegment) return <Loading />

  return (
    <>
      <SegmentsListSection
        segments={segments}
        flattenSegments={flattenSegments}
        columnData={columnData}
        handleRowClick={handleRowClick}
      />
      {segmentDetails && segments[rowIdx] && (
        <SegmentsDetails
          segmentDetailsDialog={segmentDetails}
          closeSegmentDetailsDialog={closeSegmentDetailsDialog}
          handleDeactivateSegment={handleDeactivateSegment}
          handleDuplicateSegment={handleDuplicateSegment}
          contractSource={contractSource}
          chosenSegment={segments[rowIdx]}
          segmentLeadCapTotal={segments[rowIdx].lead_total}
          disabledContract={disabledContract}
        />
      )}
    </>
  )
}
