import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Login } from 'Components/shared/login'
import { Permissions } from 'Configs/userPermissions'
import {
  useLoginStatusQuery,
  useLogInMutation,
} from 'Services/global/authentication'
import { selectUserInfo } from 'Containers/shared/login/UserInfoSlice'
import { Loading } from 'Utils/loading'
import { ContractSource } from 'Types/index'

declare var heap: any

export const LoginContainer = () => {
  const navigate = useNavigate()
  const { data: loginInfo, isLoading } = useLoginStatusQuery()
  const [login, { error }] = useLogInMutation()
  const userInfo = useSelector(selectUserInfo)

  const useHash =
    window.location.hostname === process.env.REACT_APP_HASH_ROUTING_DOMAIN
  const redirectPath = useHash ? '#inquiries' : ContractSource.inquiries

  const getDefaultURL = (isAdmin: boolean) => {
    if (isAdmin) {
      return `/admin/segments-queue`
    } else {
      return `/institution/${userInfo.ipedId}/inquiries`
    }
  }

  useEffect(() => {
    if (loginInfo?.isLoggedIn) {
      if(heap?.loaded) {
        try {
          heap.addUserProperties({ role: loginInfo?.roles[0]})
        } catch (e) {
          console.error('Error setting heap user properties', e)
        }
      }
      const isAdmin = loginInfo.permissions.includes(Permissions.SwitchInstitution)
      const storedPath = window.sessionStorage.getItem('path')
      storedPath ? navigate(storedPath) : navigate(getDefaultURL(isAdmin))
    }
  }, [loginInfo?.isLoggedIn])

  if (isLoading) return <Loading />

  const handleSSOClick = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_BASE_URL}users/auth/sso/microsoft_o_auth?fwd=${process.env.REACT_APP_BASE_URL}${redirectPath}`
    )
  }

  const handleLoginClick = ({ email, password }) => {
    login({ email, password })
  }

  return (
    !loginInfo?.isLoggedIn && (
      <Login
        loginError={error ? true : false}
        onSSOClick={handleSSOClick}
        onLoginClick={handleLoginClick}
      />
    )
  )
}
