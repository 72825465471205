import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectContractConfiguration,
  selectContractState,
  selectDeduplicatesFile,
  convertContractConfigurationToContract,
} from 'Slices/contracts'

import {
  useAddDestinationsToContractMutation,
  useCreateContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
  useUpdateContractMutation,
} from 'Services/contracts/Contracts'

import {
  useAddDeduplicateFileMutation,
  useUpdateDeduplicateFileMutation,
} from 'Services/contracts/deduplicates'
import { current } from '@reduxjs/toolkit'

interface ContractData {
  contractId: string
  institutionId: string
}

interface ContractResult {
  loading: boolean
  error: Error | null
  data: ContractData
}

const useCreateUpdateContract = (): [
  (institutionId: string) => void,
  ContractResult
] => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<ContractData>({
    contractId: '',
    institutionId: '',
  })
  const [updateContract] = useUpdateContractMutation()
  const [createContract] = useCreateContractMutation()
  const [addDeduplicateFile] = useAddDeduplicateFileMutation()
  const [updateDeduplicateFile] = useUpdateDeduplicateFileMutation()
  const [subscribeUsertoContractNotifications] =
    useSubscribeUsertoContractNotificationsMutation()
  const [addDestinationsToContract] = useAddDestinationsToContractMutation()
  const contractConfiguration = useSelector(selectContractConfiguration)
  const {
    contractInstitutionId,
    contractId,
    selectedDestinations,
    selectedLeads,
  } = contractConfiguration
  const { isDuplicating } = useSelector(selectContractState)

  const { fileId, deduplicateId } = useSelector(selectDeduplicatesFile)
  const isNewContract = contractId === null

  const mutateContractFromState = async (institutionId: string) => {
    try {
      setLoading(true)
      if (institutionId != contractInstitutionId) {
        throw new Error('Institution ID does not match')
      }
      const contractPayload = convertContractConfigurationToContract(
        contractConfiguration
      )
      // TODO: Support file format
      let response
      if (!isDuplicating && !isNewContract) {
        response = await updateContract({
          ipedId: contractInstitutionId,
          contractId: contractId,
          contract: contractPayload,
        })
      } else {
        contractPayload.ipedId = contractInstitutionId
        response = await createContract(contractPayload)
      }

      if ('error' in response) {
        throw new Error(response.error)
      }
      const currentContractId =
        (response?.data?.data?.id as string) || contractId

      if ('data' in response) {
        const destinationIds =
          selectedDestinations?.length > 0
            ? selectedDestinations.map((destination: any) => destination.id)
            : []
        const adestResponse: any = await addDestinationsToContract({
          contractId: currentContractId,
          ipedId: contractInstitutionId,
          destinationIds,
        })
        if (adestResponse && 'error' in adestResponse) {
          throw new Error(adestResponse?.error)
        }

        //subscribe user to contract notifications
        const accountIdsEnabled = selectedLeads?.length > 0 ? selectedLeads : []
        const subscribeRsponse: any =
          await subscribeUsertoContractNotifications({
            contractId: currentContractId,
            ipedId: contractInstitutionId,
            accountIdsEnabled,
          })
        if (subscribeRsponse && 'error' in subscribeRsponse) {
          throw new Error(subscribeRsponse?.error)
        }

        if (fileId) {
          const dupRepsonse: any =
            isDuplicating || isNewContract || !deduplicateId
              ? await addDeduplicateFile({
                  ipedId: contractInstitutionId,
                  contractId: currentContractId,
                  fileId: fileId,
                })
              : await updateDeduplicateFile({
                  ipedId: contractInstitutionId,
                  contractId: currentContractId,
                  fileId: fileId,
                  deduplicateId,
                })
          if (dupRepsonse && 'error' in dupRepsonse) {
            throw new Error(dupRepsonse?.error)
          }
        }
      }
      setData({
        contractId: currentContractId,
        institutionId: contractInstitutionId,
      } as ContractData)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return [mutateContractFromState, { loading, error, data }]
}

export default useCreateUpdateContract
