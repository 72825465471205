import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterBy } from 'Filter/properties'

interface MultiSelectState {
  /* selectedValues are the values currently selected in the dropdown */
  selectedValues: string[]
  /* values is the list of values that are available in the dropdown */
  values: string[]
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
}

export const multiSelectSlice = (
  column: string,
  action: string,
  values: string[],
  meta: { section: string; label: string, defaultValue?: string[] },
  transformValues?: Function,
  transformState?: Function
) => {
  return createSlice({
    name: column,
    initialState: {
      values,
      selectedValues: meta?.defaultValue ? meta.defaultValue : [],
      filterQuery: null,
    } as MultiSelectState,
    reducers: {
      [action]: (state, action: PayloadAction<string[]>) => {
        state.selectedValues = transformState ? transformState(action.payload) : action.payload
        state.filterQuery =
          action.payload.length === 0 || action.payload?.includes('Any')
            ? null
            : {
                column,
                op: FilterBy.IN,
                value:
                  typeof transformValues == 'function'
                    ? transformValues(state.selectedValues)
                    : state.selectedValues,
                meta,
              }
      },
    },
  })
}
