import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { SmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates'
import { Demographics } from 'Containers/shared/segmentfilters/ondemand/demographics'
import { Verification } from 'Containers/shared/segmentfilters/ondemand/verification'
import { ProspectProfile } from 'Containers/shared/segmentfilters/ondemand/prospectprofile'
import { useSegmentService, useSegmentData } from 'Hooks/segment'
import { SidebarActionButtons } from 'Components/shared/segmentfilters/SidebarActionButtons'
import { AddOnDemand } from 'Containers/candidates/segment/AddOnDemand'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import type { RootState } from 'Utils/store'
import { ContractSource, WebSocketMethods, SegmentAction } from 'Types/index'
import { messageSent } from 'Slices/websocketSlice'
import { useSocket } from 'Hooks/useSocket'
import { useHasPermission } from 'Hooks/useHasPermission'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { Permissions } from 'Configs/userPermissions'
import { selectGeography } from 'Containers/shared/segmentfilters/ondemand/demographics/GeographySlice'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'

interface OnDemandFilterProps {
  /** Boolean to show/hide sidebar */
  showSidebar: boolean
  /** Logic for handling closing the segment */
  handleCloseSidebar: () => void
  /** Segment action */
  segmentAction?: SegmentAction
}

export const OnDemandFilter = ({
  showSidebar,
  handleCloseSidebar,
  segmentAction,
}: OnDemandFilterProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [resetKey, setResetKey] = useState(Date.now())
  const [studentsAvailable, setStudentsAvailable] = useState(null)
  const { waitingForResponse: isFetchingStudentsAvailable } = useSelector(
    (state: RootState) => state.websocketStatus
  )
  const { sendMessage } = useSocket(setStudentsAvailable)
  const { isLoading, createSegment } = useSegmentService()
  const { hasPermission: canExploreSegments } = useHasPermission(
    Permissions.ViewEnhancedCandidatesExploreSegments
  )

  const {
    onDemandFilter,
    contract,
    noOfLeads,
    pricePerLead,
    segmentName,
    isSmartCandidates,
  } = useSegmentData()

  const geography = useSelector(selectGeography)

  const { data: contractConfig } = useGetContractConfigQuery(
    {
      contractSource: ContractSource.candidates,
      audienceType: contract?.audience_type,
    },
    { skip: !contract?.audience_type }
  )

  const {
    control,
    formState: { errors: formErrors, isValid },
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm({
    mode: 'onChange',
  })

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count_contract_id,
      data: {
        contract_id: contract?.id,
        is_smart_candidate: isSmartCandidates,
        institution_id: ipedId,
        contract_source: ContractSource.candidates,
        audience_type: contract?.audience_type,
        filter: buildFilterQuery(
          onDemandFilter,
          excludeKeys(ContractSource.candidates, contract?.audience_type),
          false
        ),
      },
    }

    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }

    if (isSmartCandidates) {
      message.data['override_sources'] = ['candidates', 'smart_candidates']
    } else {
      message.data['override_sources'] = ['candidates']
    }

    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
  }

  const activateSegment = () => {
    const filterQuery = buildFilterQuery(
      onDemandFilter,
      excludeKeys(ContractSource.candidates, contract?.audience_type),
      false
    )
    const segmentObj = {
      name: segmentName,
      ipedId,
      contract_id: contract.id,
      lead_total: noOfLeads,
      price_per_lead: pricePerLead,
      is_smart_candidates: isSmartCandidates,
      filter: filterQuery,
    }
    if (geography.zipFile.id) {
      segmentObj['zip_id'] = geography.zipFile.id
      segmentObj['zip_filter_exclusive'] = false
    }
    createSegment(segmentObj)
      .unwrap()
      .then(() => {
        handleCloseSidebar()
      })
  }

  const resetFilters = () => {
    dispatch({
      type: 'onDemandFilter/reset',
    })
    setResetKey(Date.now())
  }

  /** Disable form submission if any reqired field is not filled
   * (or) there are any form errors */
  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  const studentsCount = studentsAvailable?.hasOwnProperty('total_count')
    ? studentsAvailable.total_count
    : studentsAvailable

  return (
    <>
      {isLoading || (isFetchingStudentsAvailable && <Loading />)}
      <div
        style={{
          width: '396px',
        }}
      >
        <AddOnDemand
          control={control}
          setFormError={setFormError}
          clearFormErrors={clearFormErrors}
          showSidebar={showSidebar}
        />

        <FilterHeader
          availableStudents={studentsCount || 0}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={contract?.id ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
          {canExploreSegments &&
            contractConfig?.sections_available?.includes('SmartCandidates') && (
              <SmartCandidates chosenItem={contract} />
            )}
          <Demographics
            chosenItem={contract}
            control={control}
            resetKey={resetKey}
          />
          <ProspectProfile chosenItem={contract} resetKey={resetKey} />
          <Verification chosenItem={contract} />
        </div>
        <SidebarActionButtons
          handleSegmentAction={activateSegment}
          handleCloseSidebar={handleCloseSidebar}
          disableFormSubmit={disableFormSubmit || isLoading}
          segmentAction={segmentAction}
        />
      </div>
    </>
  )
}
