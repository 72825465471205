import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { Verification } from 'Containers/shared/segmentfilters/ondemand/verification'
import { Demographics } from 'Containers/shared/segmentfilters/ondemand/demographics'
import { ProspectProfile } from 'Containers/shared/segmentfilters/ondemand/prospectprofile'
import { SmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { SidebarField } from 'Components/shared/sidebar'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { selectSmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates/SmartCandidatesSlice'
import type { RootState } from 'Utils/store'
import {
  AudienceTypeHelper,
  ContractSource,
  WebSocketMethods,
} from 'Types/index'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { useSocket } from 'Hooks/useSocket'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { messageSent } from 'Slices/websocketSlice'
import { Contract } from 'Services/contracts/contractsUtils'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Permissions } from 'Configs/userPermissions'
import { selectGeography } from 'Containers/shared/segmentfilters/ondemand/demographics/GeographySlice'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import { SidebarActionButtons } from 'Components/shared/segmentfilters/SidebarActionButtons'
import AudienceTypes from 'Configs/audienceTypes.json'

interface OnDemandExploreSegmentFilterProps {
  /** Object to derive various properties, like label text for buttons, etc */
  segmentAction?: any
  /** Logic for handling closing the sidebar */
  close?: () => void
}

export const OnDemandExploreSegmentsFilter = ({
  segmentAction,
  close,
}: OnDemandExploreSegmentFilterProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [resetKey, setResetKey] = useState(Date.now())
  const [studentsAvailable, setStudentsAvailable] = useState(null)
  const { waitingForResponse: isFetchingStudentsAvailable } = useSelector(
    (state: RootState) => state.websocketStatus
  )
  const { sendMessage } = useSocket(setStudentsAvailable)

  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.candidates)
  const geography = useSelector(selectGeography)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const onDemandExploreSegmentsFilter = useSelector(
    (state: RootState) => state.onDemandFilter
  )

  const { isSmartCandidates } = useSelector(selectSmartCandidates)
  const [currentAudienceType, setCurrentAudienceType] = useState(
    AudienceTypeHelper.getNone()
  )
  const { hasPermission: canExploreSegments } = useHasPermission(
    Permissions.ViewEnhancedCandidatesExploreSegments
  )

  const { data: contractConfig } = useGetContractConfigQuery(
    {
      contractSource: ContractSource.candidates,
      audienceType: currentAudienceType,
    },
    { skip: !currentAudienceType }
  )

  const resetFilters = () => {
    dispatch({
      type: 'onDemandFilter/reset',
    })
    setResetKey(Date.now())
  }

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count,
      data: {
        is_smart_candidate: isSmartCandidates,
        institution_id: ipedId,
        contract_source: ContractSource.candidates,
        audience_type: currentAudienceType,
        filter: buildFilterQuery(
          onDemandExploreSegmentsFilter,
          excludeKeys(ContractSource.candidates, currentAudienceType),
          false
        ),
        group_by_fields: [
          //migrate this to state once redux is refactored
          'high_school_grad_year',
          'state_abbr',
          'gender',
        ],
        max_look_back_period:
          (AudienceTypes[currentAudienceType] &&
            AudienceTypes[currentAudienceType]['contract_source']['candidates']
              .lookback_period_integer) ||
          720,
      },
    }
    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }
    if(isSmartCandidates) {
      message.data['override_sources'] = ["candidates", "smart_candidates"]
    } else {
      message.data['override_sources'] = ["candidates"]
    }

    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
  }

  let chosenItem: Partial<Contract> = undefined
  if (!AudienceTypeHelper.isNone(currentAudienceType))
    chosenItem = {
      id: '123-' + currentAudienceType,
      audience_type: currentAudienceType,
    }

  const studentsCount =
    (studentsAvailable &&
      (Array.isArray(studentsAvailable)
        ? studentsAvailable?.reduce((acc, grouping) => grouping.count + acc, 0)
        : studentsAvailable)) ||
    0

  return (
    <>
      {(isFetchingStudentsAvailable || isFetchingAudienceTypes) && <Loading />}
      <div
        style={{
          width: '396px',
        }}
      >
        <SidebarField id='select-inquiries-type' label='Audience Type'>
          <SingleSelectDropdown
            id='select-audience-type'
            name='inquiriesType'
            value={
              currentAudienceType
                ? AudienceTypeHelper.getReadableString(
                    audienceTypes,
                    currentAudienceType
                  )
                : ''
            }
            style={matches ? { width: '229px' } : {}}
            values={AudienceTypeHelper.getAudienceTypeList(audienceTypes)}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const chosenItem = AudienceTypeHelper.getAudienceType(
                audienceTypes,
                e.target.value
              )
              setResetKey(Date.now())
              if (chosenItem) {
                setCurrentAudienceType(chosenItem)
              }
            }}
          />
        </SidebarField>
        <FilterHeader
          availableStudents={studentsCount}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={currentAudienceType ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
          {canExploreSegments &&
            contractConfig?.sections_available?.includes('SmartCandidates') && (
              <SmartCandidates chosenItem={chosenItem} />
            )}
          <Demographics chosenItem={chosenItem} resetKey={resetKey} />
          <ProspectProfile chosenItem={chosenItem} resetKey={resetKey} />
          <Verification chosenItem={chosenItem} />
        </div>
        {close && (
          <SidebarActionButtons
            handleSegmentAction={getAvailableStudents}
            handleCloseSidebar={close}
            disableFormSubmit={
              isFetchingStudentsAvailable || isFetchingAudienceTypes
            }
            segmentAction={segmentAction}
          />
        )}
      </div>
    </>
  )
}
